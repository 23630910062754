$action-color: #adb0fd;
$primary-light-color: rgb(25, 142, 231);
$gray-color: rgb(108, 111, 112);
$light-gray-color: rgb(204, 204, 204);
$accent-color: rgb(235, 68, 28);
$font-size: 14px;
$secondary-font-size: 14px;
$ag-icons-path: '~ag-grid-community/src/styles/ag-theme-material/icons/';
$hover-color: rgb(233, 246, 253);
$selected-color: $hover-color;

@import '~react-toastify/dist/ReactToastify.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '~react-placeholder/lib/reactPlaceholder.css';
@import '~rc-slider/assets/index.css';
@import '~react-tabs/style/react-tabs.scss';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#form-submit-button {
  background: 'linear-gradient(114.24deg,rgb(38, 172, 151) 17.28%, rgb(59, 63, 182) 84.62%)!important';
}

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?u10x4e');
  src: url('fonts/icomoon.eot?u10x4e#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?u10x4e') format('truetype'),
    url('fonts/icomoon.woff?u10x4e') format('woff'),
    url('fonts/icomoon.svg?u10x4e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-incident_management:before {
  content: '\e900';
}
.icon-icon_collection:before {
  content: '\e901';
}

body::-webkit-scrollbar {
  display: none;
}
html,
body {
  -ms-overflow-style: none;
  overflow: auto;
}

/* Tab overrides */

.react-tabs__tab-list {
  border: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.4; /* Firefox */
  font-size: 10;
}

.react-tabs__tab {
  &:hover {
    color: $primary-light-color;
  }
  border: none;
  border-radius: 0;
  color: $light-gray-color;
}

.react-tabs__tab:not(:first-child) {
  padding-left: 35px;
}

.react-tabs__tab:not(:last-child) {
  padding-right: 35px;
}

.react-tabs__tab:not(:last-child):after {
  background-color: $light-gray-color;
  content: '';
  height: 20px;
  position: absolute;
  right: 0;
  top: 5px;
  width: 1px;
}

/* The tabs put their own after element in that seems unnecessary in this setup. */
.react-tabs__tab:focus:after {
  background-color: transparent;
}

.react-tabs__tab--selected {
  background-color: inherit;
  color: $action-color;
}

.react-tabs__tab-panel--selected {
  height: calc(100% - 41px);
}

/* Grid overrides scss */

.ag-react-container {
  display: flex;
  width: 100%;
}

.ag-font-style {
  user-select: text !important;
}

span.ag-cell-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.ag-theme-material {
  .ag-header-cell:hover {
    background: #fff !important;
  }

  .ag-header-cell-label .ag-header-cell-text {
    font-weight: 600;
  }

  .ag-header-cell-label .ag-header-icon {
    cursor: pointer;
  }

  .ag-header-cell.ag-header-cell-sortable {
    display: inline-flex;
  }

  .ag-header-row {
    border-bottom: none;
    position: relative;
  }

  // these are hardcoded values to duplicate the theme separators for the ag grid
  .ag-header-row:after {
    background-color: $light-gray-color;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 15px;
    position: absolute;
    right: 15px;
  }

  .ag-ltr .ag-cell-focus {
    border-color: #11284b;
  }

  .ag-cell {
    display: flex;
    align-items: center;
  }

  .ag-cell-label-container {
    line-height: 46px;
  }

  .ag-header-cell-resize {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: ew-resize;
    height: 20px;
    right: 0;
    top: 15px;
    width: 1px !important;
  }
}

/* Query builder scss */

.rule,
.ruleGroup .ruleGroup {
  margin-top: 0.5rem;
}

/* Draftjs wysiwyg editor */

.public-DraftEditorPlaceholder-root {
  color: rgb(108, 111, 112);
  font-family: Open sans, sans-serif;
  font-weight: 300;
  letter-spacing: 0.825px;
}

/* Reacttagsinput styling */
.react-tagsinput {
  background-color: #fff;
  border: 2px solid #ebebeb;
  min-height: 35px;
  overflow: visible;
  padding: 10px;
  width: calc(100% - 20px);
}

.react-tagsinput-tag {
  background: #f2f2f2;
  border: 0;
  color: #6c6f70;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 8px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: ' ×';
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}

.react-autosuggest__container {
  position: relative;
  display: inline-block;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 300px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-container--open.suggestions___position__above {
  bottom: 51px;
}
.react-autosuggest__suggestions-container--open.suggestions___position__below {
  top: 51px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 13px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.Toastify__close-button {
  padding-top: 12px;
}

/* React Datepicker styling */

.react-datepicker-popper {
  z-index: 3;
}

.Toastify__progress-bar--default {
  background: $gray-color;
}

.Toastify__toast-body {
  margin: 0;
}

.Toastify__toast-container {
  width: 380px;
}

.Toastify__toast-container--top-right {
  top: 4em;
  right: 0;
  padding-right: 0;
}

.Toastify__toast--default {
  background: #000000;
  opacity: 0.8;
  color: #aaa;
}

.report-scheduling-input {
  background: #fff;
  cursor: pointer;
  font-size: 14px;
  border: 2px solid $gray-color;
  padding: 5px 4px;
  margin-right: 15px;
}
